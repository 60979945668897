<template>
  <b-row class="mx-0 flex-grow-1 flex-shrink-1 flex-nowrap flex-basis-auto">
    <div
      v-if="hasPaymentPlatform"
      class="
        col-lg-12
        box
        bg-products
        d-none d-md-block
        position-fixed position-md-relative
        h-100 h-md-auto
        top-0
        px-0 px-md-3
        fixable
        d-block
        overflow-x-hidden
      "
      style="z-index: 10"
    >
      <h5 class="mb-3 d-flex justify-content-between">
        <span>
          <a href="#" class="d-inline-block mr-3" @click.prevent="back">
            <i class="fas fa-arrow-left"></i>
            {{ $t("title.back") }}
          </a>
        </span>
      </h5>
      <div class="flex-grow-1 py-lg-5" v-if="!make_payment && !isSaving">
        <div class="d-flex flex-column h-100">
          <b-col class="">
            <div class="text-center pt-4 pb-0">
              <h2 class="font-size-pay text-info">
                {{ $t("info.select_options") }}:
              </h2>
            </div>

            <div>
              <b-button
                :title="$t('title.request_account')"
                class="btn-req-bill ml-2 mb-1"
                variant="warning"
                size="md"
                @click.prevent="makeRequest()"
                :disabled="callingWaiter || requestingInfo"
              >
                <b-icon class="fa-4x" icon="file-earmark-text"></b-icon>
                {{ $t("title.request_account") }}
              </b-button>
              <b-button
                :title="$t('title.request_account')"
                class="btn-req-bill ml-2 mb-1"
                variant="success"
                size="md"
                @click.prevent="preparePayAccount()"
                :disabled="callingWaiter || requestingInfo"
              >
                <b-icon class="fa-4x" icon="credit-card"></b-icon>
                {{ $t("title.make_payment") }}
              </b-button>
            </div>
          </b-col>
        </div>
      </div>
      <div class="flex-grow-1 py-lg-5" v-if="make_payment && !isSaving">
        <div class="d-flex flex-column h-100 text-sm">
          <div class="text-center pt-4 pb-0">
            <h4 class="text-info font-size-table-waiter">
              {{ $t("info.consumption_note") }}
            </h4>
          </div>
          <div class="d-flex justify-content-between align-items-center p-3">
            <!-- <h5 class="text-dark m-0" v-if="isToGo">{{ $tc('account_to_go') }}</h5>-->
            <h5 class="m-0 text-info font-size-table-waiter">
              {{ $tc("title.table", 1) }}: {{ tableName }}
            </h5>
            <!-- <h5 class="text-dark m-0" v-if="isToGo">{{ $tc('attended_by') }}: {{waiterName}}</h5> -->
            <h5 class="text-info m-0 font-size-table-waiter">
              {{ $tc("title.waiter", 1) }}: {{ waiterName }}
            </h5>
          </div>

          <b-table
            responsive
            head-variant="light"
            small
            :fields="fields"
            :items="purchases"
          >
            <template v-slot:cell(quantity)="data">
              {{ data.item.quantity }}
            </template>
            <template v-slot:cell(name)="data">
              {{
                data.item.purchasable_type == "extra"
                  ? $tc("title.extras", 1) +
                    " " +
                    $t("title.of", 1) +
                    " " +
                    data.item.purchasable.name
                  : data.item.purchasable.name
              }}
            </template>
            <template v-slot:cell(price)="data">
              {{ productPrice(data.item) }}
            </template>
            <template v-slot:cell(amount)="data">
              {{ productAmount(data.item) }}
            </template>
          </b-table>
          <div class="d-flex align-items-center justify-content-center p-3">
            <span>{{ $t("info.suggested_tips") }}:</span>
          </div>
          <div
            class="
              d-flex
              align-items-center
              justify-content-center
              button-span-align
            "
          >
            <b-button-group>
              <b-button
                pill
                size="sm"
                :variant="isSavingTip ? 'success' : 'outline-success'"
                @click="saveTip()"
                >{{ formatMoney(0) }}</b-button
              >
              <b-button
                v-for="(tipSetting, index) in tipsSettings"
                :key="`tip_${index}`"
                pill
                size="sm"
                :variant="currentIndex == index ? 'success' : 'outline-success'"
                @click="saveTipSetting(tipSetting, index)"
                ><span class="font-size-purchase"
                  >{{ tipSetting.value }}%({{ tipSetting.name }}):{{
                    formatMoney(getTip(tipSetting))
                  }}</span
                ></b-button
              >
            </b-button-group>
          </div>
        </div>

        <div class="bill-container">
          <ButtonPay
            @payAccount="payAccount"
            :status="status"
            :isSaving="isSaving || isSavingTip || isSavingTipGood || isSavingTipExcelent "
            :total="total"
          />
        </div>
      </div>
      <Paypal
        v-if="isSaving"
        :order="order"
        :paymentPlatforms="paymentPlatforms"
        @on-successful-payment="paySuccessful"
        :currentQRCode="currentQRCode"
      >
      </Paypal>
      <vtr-alert :alerts="alerts"></vtr-alert>
    </div>
  </b-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { formatMoney } from "../../js/utils.js";
import ButtonPay from "./ButtonPay.vue";
import Paypal from "./Paypal.vue";
import axios from "axios";
import debounce from "lodash/debounce";
import { i18n } from "./../../i18n-setup";

//import Hashids from "hashids";
//const hashids = new Hashids("M0D3RN_R3574UR4N7-2021", 4);

export default {
  name: "payaccount",
  props: ["callingWaiter", "company"],
  data() {
    return {
      isSavingTip: false,
      isSavingTipGood: false,
      isSavingTipExcelent: false,
      alerts: [],
      requestingInfo: false,
      windowWidth: 0,
      make_payment: false,
      accountSelectedPay: "1",
      total: 0,
      status: true,
      isSaving: false,
      tipPaypal: null,
      order: [],
      paymentPlatforms: [],
      openBox: [],
      paymentMethodId: null,
      exchangeRateCode: "MXN",
      exchangeRateValue: 1,
      currentIndex: null,
    };
  },
  components: {
    ButtonPay,
    Paypal,
  },
  mounted() {
    this.total = this.getTotal(this.purchases);
    this.gethasPaymentPlatform();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapState({
      hotel: (state) => state.hotel,
      menuTemplate: (state) => state.menuTemplate,
      commandData: (state) => state.command,
      accounts: (state) => state.command.open_table.table_accounts,
      waiter: (state) => state.command.open_table.waiter,
      waiterAction: (state) => state.waiterAction,
      client: (state) => state.client,
      closeOpenTable: (state) => state.closeOpenTable,
      isLoadingCommand: (state) => state.isLoadingCommand,
      hasPaymentPlatform: (state) => state.hasPaymentPlatform,
      accountSelected: (state) => state.accountSelected,
      currentQRCode: (state) => state.currentQRCode,
      tipsSettings: (state) => state.tipsSettings,
      tableName: (state) => state.tableName,
      table: (state) => state.table,
    }),
    ...mapGetters(["isValidTable"]),
    hotelName() {
      return this.hotel != null ? this.hotel.name : null;
    },
    waiterName() {
      console.log("command", this.commandData);
      return this.commandData != null
        ? this.commandData.open_table.waiter.name
        : "";
    },

    routeName() {
      return this.$route.name;
    },
    purchases() {
      let purchases = [];
      let self = this;
      this.accounts.filter(function (account) {
        if (account.account == self.accountSelectedPay) {
          account.purchases.map((p) => {
            purchases.push(p);
            if (p.extras.length > 0) {
              p.extras.map((e) => {
                purchases.push(e);
              });
            }
          });
        }
      });

      return purchases;
    },

    accountId() {
      let account_id = 0;
      let self = this;
      this.accounts.filter(function (account) {
        if (account.account == self.accountSelectedPay) {
          account_id = account.id;
        }
      });

      return account_id;
    },

    fields() {
      let showOnSMClass = "d-none d-sm-table-cell";
      return [
        { key: "quantity", label: this.$t("title.quantity") },
        { key: "name", label: this.$tc("title.product") },
        {
          key: "price",
          label: this.$tc("title.price"),
          thClass: showOnSMClass,
          tdClass: showOnSMClass,
        },
        { key: "amount", label: this.$t("title.amount") },
        // {key:'quantity',label:'Cantidad'},
      ];
    },
  },
  methods: {
    getTip(tip) {
      let tip_good = 0;
      let total = this.getTotal(this.purchases);
      tip_good = (tip.value * total) / 100;
      return tip_good;
    },
    formatMoney,
    gethasPaymentPlatform() {
      if (this.hasPaymentPlatform) {
        this.getTipsSettings();
        this.getPaymentPlatforms();
        this.getOpenBox();
        this.getPaymentMethodId();
      } else {
        this.goMain();
      }
    },

    async getTipsSettings() {
      this.requestingInfo = true;
      this.$http
        .get(
          this.$root.tenantApiUrlBase +
            `/api-v2/tips-settings/` +
            this.currentQRCode +
            `/hotels/` +
            this.hotel.id
        )
        .then((response) => {
          //let setting_id = 0;
          this.tipsSettings.push(response.data.tips);

          this.$store.commit("setTipsSettings", response.data.tips);

          this.requestingInfo = false;
        })
        .catch((e) => {
          console.log("e", e);
          this.requestingInfo = false;
        });
    },

    async getOpenBox() {
      this.requestingInfo = true;
      this.$http
        .get(
          this.$root.tenantApiUrlBase +
            `/api-v2/open-box/` +
            this.currentQRCode +
            `/hotels/` +
            this.hotel.id
        )
        .then((response) => {
          //let setting_id = 0;
          console.log("OpenBox", response);
          this.openBox = response.data.open_box;
          this.requestingInfo = false;
        })
        .catch((e) => {
          console.log("e", e);
          this.requestingInfo = false;
        });
    },
    async getPaymentMethodId() {
      let name = "paypal";
      this.requestingInfo = true;
      this.$http
        .get(
          this.$root.tenantApiUrlBase +
            `/api-v2/table-accounts/` +
            this.currentQRCode +
            `/payment-method/` +
            name
        )
        .then((response) => {
          //let setting_id = 0;
          console.log("getPaymentMethodId", response);

          this.paymentMethodId = response.data.paymentMethodId.id;

          this.requestingInfo = false;
        })
        .catch((e) => {
          console.log("e", e);
          this.requestingInfo = false;
        });
    },
    async getPaymentPlatforms() {
      this.requestingInfo = true;
      this.$http
        .get(
          this.$root.tenantApiUrlBase +
            `/api-v2/payment-platforms/` +
            this.currentQRCode +
            `/hotels/` +
            this.hotel.id
        )
        .then((response) => {
          //let setting_id = 0;
          console.log("response", response);
          this.paymentPlatforms = response.data.payment_platforms;
          //this.$store.commit("setTipsSettings", response.data.tips);
          this.requestingInfo = false;
        })
        .catch((e) => {
          console.log("e", e);
          this.requestingInfo = false;
        });
    },
    makeRequest() {
      this.$emit("makerequest");
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },
    payAccount() {
      this.makeOrderForPayment();
      this.isSaving = true;
    },
    makeOrderForPayment() {
      this.order = {
        id: this.accountId,
        currency: {
          code: "MXN",
        },
        items: this.purchases,
        tipPaypal: this.tipPaypal,
        discount: 0,
        tax_total: 0,
        amount_total: this.total,
        item_total: this.total,
        persons: {
          first_name: "Prueba",
          last_name: "Client",
          email: "prueba@hotmail.com",
        },
      };
    },
    paySuccessful(successful) {
      if (successful) {
        this.$swal.close();
        this.isSaving = false;
        this.addPayment();
        this.deleteAssigmentTable();
        this.closeAccount();
      }
    },

    async addPayment() {
      try {
        let timerInterval;
        let title = i18n.tc("title.account", 1);
        let text = i18n.t("messages.wait_save_pay_account");
        this.$swal({
          title: title,
          timer: 30000,
          html: text,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        const params = {
          table_account_id: this.accountId,
          payment_method: this.paymentMethodId,
          card_type: null,
          terminal: null,
          quantity: this.total,
          tips: 0,
          currency: "MXN",
          discount: 0,
          // discount_type: 'percent',
          discount_type: "percent",
          discount_comments: "",
          auth: null,
          reference: null,
          digits: null,
          box_id: this.openBox.id,
          box: this.openBox,
          exchange_rate_code: "MXN",
          exchange_rate: "1.0",
        };

        console.log("params", params);

        const response = await axios.post(
          this.$root.tenantApiUrlBase +
            "/api-v2/table-accounts/" +
            this.currentQRCode +
            "/save-payment",
          params
        );

        console.log("response", response);
        this.$swal.close();
        //this.$emit("on-successful-payment", true);
      } catch (error) {
        this.$swal({
          title: "Información de Pago",
          text: error.response.data.message,
          icon: "error",
        });

        this.$emit("on-error-payment", false);
      }
    },
    async closeAccount() {
      try {
        let title = i18n.tc("title.account", 1);
        let text = i18n.t("messages.thanks_close_account");
        let timerInterval;

        this.$swal.fire({
          title: title,
          html: text,
          timer: 30000,
          didOpen: () => {
            this.$swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });

        let sale_total = this.getTotal(this.purchases);

        const params = {
          table_account_id: this.accountId,
          box: this.openBox.box_id,
          open_box_id: this.openBox.id,
          balance: 0,
          exchange_rate_code: this.exchangeRateCode,
          exchange_rate: this.exchangeRateValue,
          sale_total: sale_total * 100,
          hotel_id: this.hotel.id,
          company_id: this.company.id,
        };

        console.log("params", params);

        const response = await axios.post(
          this.$root.tenantApiUrlBase +
            "/api-v2/table-accounts/" +
            this.currentQRCode +
            "/close",
          params
        );
        console.log("response", response);
      } catch (error) {
        let title = i18n.tc("account", 1);
        console.log("closeaccount", error);
        this.$swal({
          title: title,
          text: error.response.data.message,
          icon: "error",
        });

        //this.$emit("on-error-payment", false);
      }
    },

    async deleteAssigmentTable() {
      try {
        console.log("table", this.table);
        const params = {
          table_id: this.table.id,
          user_id: this.waiter.id,
        };

        axios
          .put(
            this.$root.tenantApiUrlBase +
              "/api-v2/table-reservations/" +
              this.currentQRCode +
              "/delete-assigment",
            params
          )
          .then((r) => {
            console.log("deleteAssigments:", r);
          })
          .catch((e) => {
            let aMessage = "Error: " + e.message;
            console.log("deleteAssigments:Error", aMessage);
            this.getTableAssigments();
          });
      } catch (error) {
        console.log("closeaccount", error);
      }
    },

    preparePayAccount() {
      this.make_payment = true;
      this.total = this.getTotal(this.purchases);
    },
    saveTips: debounce(function () {
      let amount = this.tipPaypal != null ? this.tipPaypal.amount / 100 : 0;
      axios
        .put(
          this.$root.tenantApiUrlBase +
            `/api-v2/table-accounts/` +
            this.currentQRCode +
            `/save-tips`,
          {
            tips: amount,
            exchange_rate_code: "MXN",
            exchange_rate: "1.0",
            table_account_id: this.accountId,
          }
        )
        .then((r) => {
          this.isSavingTip = false;
          this.isSavingTipGood = false;
          this.isSavingTipExcelent = false;
          console.log(r);
        })
        .catch((e) => {
          this.isSavingTip = false;
          this.isSavingTipGood = false;
          this.isSavingTipExcelent = false;
          console.log(e);
        });
    }, 500),
    back() {
      if (this.make_payment) {
        if (this.isSaving) {
          this.isSaving = false;
        } else {
          this.make_payment = false;
        }
      } else {
        this.goMain();
      }
    },
    goMain() {
      this.$router.push({
        name: "Home",
        params: { id: this.$root.table_qrcode },
      });
    },

    productPrice(purchase) {
      let amount = purchase.amount / 100;
      return formatMoney(amount);
    },
    productAmount(purchase) {
      let amount = (purchase.amount * purchase.quantity) / 100;
      return formatMoney(amount);
    },

    getTotal(purchases) {
      const products = purchases.reduce(
        (acc, purchase) => acc + (purchase.amount / 100) * purchase.quantity,
        0
      );
      let total = products;
      return total;
    },
    saveTip() {
      this.currentIndex = null;
      this.isSavingTip = true;
      this.isSavingTipGood = false;
      this.isSavingTipExcelent = false;
      let total = this.getTotal(this.purchases);
      this.total = total + 0;
      this.tipPaypal = null;
      this.saveTips();
    },
    saveTipSetting(tip, index) {
      this.isSavingTipGood = true;
      this.isSavingTipExcelent = true;
      console.log("saveTipSetting", index);
      this.currentIndex = index;
      this.isSavingTip = false;
      let amount = this.getTip(tip);
      let total = this.getTotal(this.purchases);
      this.total = total + amount;
      this.tipPaypal = {
        purchasable: {
          name: "tip " + tip.name,
        },
        amount: amount * 100,
        quantity: 1,
      };
      this.saveTips();
    },
  },
  watch: {
    waiterAction: function (newVal) {
      console.log("actionWaiter:", newVal);
      this.$store.dispatch("waiterActions");
      this.$store.dispatch("buttonBillData");
      this.total = this.getTotal(this.purchases);
    },
  },
};
</script>