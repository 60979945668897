<template>
  <main class="d-flex flex-grow-1 flex-shrink-1 overflow-hidden">
    <vtr-alert :alerts="alerts"></vtr-alert>
    <div
      id="show-sidebar-container"
      title="Menu principal"
      v-if="!isInvalidRestaurant && isValidTable"
    >
      <b-row>
        <b-col cols="8 d-inline-flex">
          <h3 v-if="$route.name != 'Home'">
            <b-link
              class="text-danger font-weight-bold mr-2"
              @click.prevent="goMain"
              ><i class="fas fa-home"></i
            ></b-link>
          </h3>
          <h4 class="text-white text-truncate">
            {{ hotel != null && hotel.name }}
          </h4>
        </b-col>
        <b-col cols="4">
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown
              toggle-class="text-white"
              :text="$t('title.lang') + ': ' + $i18n.locale"
            >
              <b-dropdown-item href="#"
                ><a
                  class="dropdown-item text-dark"
                  href="#"
                  @click.prevent="changeLang('es')"
                >
                  es
                </a></b-dropdown-item
              >
              <b-dropdown-item href="#">
                <a
                  class="dropdown-item text-dark"
                  href="#"
                  @click.prevent="changeLang('en')"
                >
                  en
                </a></b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-col>
      </b-row>
    </div>

    <div class="flex-grow-1 py-lg-5" v-if="isInvalidCompany">
      <div class="d-flex flex-column">
        <div class="text-center pt-5 pb-0">
          <img
            src="../assets/logo-modern-restaurant-sidebar.png"
            class="img-fluid mb-5"
            alt="logo"
          />
        </div>
        <div
          class="
            flex-grow-1
            d-flex
            justify-content-center
            align-items-center
            py-3
          "
        >
          <div
            v-if="requestingInfo"
            class="d-flex justify-content-center mb-3"
            style="color: #495057"
          >
            <b-spinner
              variant="info"
              style="width: 3rem; height: 3rem"
              type="grow"
            ></b-spinner>
            <h3 class="text-info">
              <strong class="ml-2" style="font-size: 2rem">
                {{ $t("info.wait") }}</strong
              >
            </h3>
          </div>

          <b-col
            sm="10"
            md="10"
            lg="6"
            xl="6"
            v-else-if="isInvalidCompany"
            class="pl-4 pr-4"
          >
            <div class="input-group">
              <b-form-input
                type="search"
                class="form-control token-form-control"
                v-model="qrcode"
                title="Ingresa un código"
                placeholder="ej: a1b2"
                autocomplete="off"
                autofocus
                :disabled="requestingInfo"
                id="company_code"
                ref="company_code"
                @keyup.enter="reloadPage"
                minlength="1"
                maxlength="15"
              />
              <div class="input-group-btn">
                <button
                  id="tokenbtn"
                  class="btn-send-token"
                  @click.prevent="reloadPage"
                  :disabled="requestingInfo"
                >
                  {{ requestingInfo ? $t("info.wait") : $t("info.connecting") }}
                </button>
              </div>
            </div>
          </b-col>

          <b-col md="10" lg="6" v-else-if="isInvalidRestaurant">
            <img src="../assets/hotel.png" style="width: 30%" />
            <h4 class="text-info">{{ $t("info.requested_restaurant") }}</h4>
          </b-col>
        </div>
      </div>
    </div>
    <Table
      v-if="!isInvalidCompany && !isInvalidRestaurant && !requestingInfo"
      :company="company"
    />
  </main>
</template>

<script>
import Echo from "laravel-echo";
import Table from "./Table";
const API_BASE_URL = process.env.VUE_APP_LOCAL_TEST ? process.env.VUE_APP_LOCAL_TEST: process.env.VUE_APP_API_MASTER_BASE_URL;
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      company: {},
      hotel: {},
      qrcode: null,
      requestingInfo: false,
      alerts: [],
    };
  },
  created() {
    this.setInitialKey();
  },
  mounted() {
    if (typeof this.$refs.company_code != "undefined")
      this.$refs.company_code.focus();
  },
  components: {
    Table,
  },
  methods: {
    changeLang(lang) {
      this.$store.commit("setLang", lang);
    },
    goMain() {
      this.hideProductPanel();
      this.hideComposableProductPanel();
      this.$router.push({
        name: "Home",
        params: { id: this.$root.table_qrcode },
      });
    },
    hideProductPanel() {
      if (this.productBeingEdited == null) {
        this.$store.dispatch("hideProductPanel");
        this.$store.commit("showProducts");
      } else {
        this.$store.dispatch("hideProductPanel");
      }
    },
    ...mapActions(["hideComposableProductPanel"]),
    setInitialKey() {
      this.qrcode = this.currentQRCode;
      if (this.qrcode != null) this.findCompany();
    },
    findCompany() {
      if (typeof this.qrcode != "undefined" && this.qrcode != null) {
        this.$store.commit("setTableQrCode", this.qrcode);
        this.requestingInfo = true;
        this.$http
          .get(API_BASE_URL + "/api-v2/companies/" + this.qrcode)
          .then((response) => {
            const resp = response.data.data;
            if (resp.company != null) this.company = resp.company;
            if (resp.hotel != null) this.hotel = resp.hotel;
            this.$store.commit("setHotel", this.hotel);
            this.$store.commit("loadTenant", this.company);
            if (this.isInvalidCompany) {
              let aMessage = this.$t("messages.invalid_code");
              this.alerts = [];
              this.alerts.push({
                title: "Mesas",
                content: aMessage,
                type: "danger",
              });
              this.qrcode = null; //Watch out, here!
            } else {
              this.createWebSocketConnection();
              this.getHasPlatformsPayment();
            }
            this.requestingInfo = false;
          })
          .catch((error) => {
            let aMessage = this.$t("messages.error_code") + error;

            if (error.response && error.response.status == "404") {
              aMessage = this.$t("messages.invalid_code");
            }

            this.alerts = [];
            this.alerts.push({
              title: "Mesas",
              content: aMessage,
              type: "danger",
            });
            this.requestingInfo = false;
          });
      }
      if (typeof this.$refs.company_code != "undefined")
        this.$refs.company_code.focus();
    },
    createWebSocketConnection() {
      if (this.company.pusher_key) {
        window.Pusher = require("pusher-js");
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: this.company.pusher_key,
          cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
          wssHost: process.env.VUE_APP_WEBSOCKETS_HOST,
          wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
          wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
          wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
          encrypted: true,
          disableStats: true,
          disabedTransports: ['ws']
        });
        console.log(
          "Home.vue:createWebSocketConnection:window.Echo:",
          window.Echo
        );
        window.Echo.connector.pusher.connection.bind("error", (error) => {
          /*
                        You can also bind to the error event, which is emitted whenever a connection error occurs:
                    */
          if (error.error.data.code === 4004) {
            console.log(">>> Detected limit error on WebSocket connection.");
          }
          console.log("Connection error", error);
          this.webSocketServerOnline = false;
        });

        window.Echo.connector.pusher.connection.bind(
          "state_change",
          (states) => {
            /* Binding to all state changes */
            switch (states.current) {
              case "connecting":
                /*
                              All dependencies have been loaded and Channels is trying to connect.
                              The connection will also enter this state when it is trying to reconnect after a connection failure.
                            */
                console.log(
                  "Home.vue:createWebSocketConnection:states:connecting",
                  states
                );
                console.log("Conectando con el Servidor de WebSockets...");
                break;
              case "connected":
                /* The connection to Channels is open and authenticated with your app. */
                console.log(
                  "Conexion establecida con el Servidor de WebSockets"
                );
                console.log(
                  "Home.vue:createWebSocketConnection:states:connected",
                  states
                );
                break;
              case "disconnected":
                /*
                                The Channels connection was previously connected and has now intentionally been closed.
                            */
                console.log(
                  "Home.vue:createWebSocketConnection:states:disconnected",
                  states
                );
                console.log(
                  "No se pudo establecer conexion con el Servidor de WebSockets"
                );
                break;
              default:
                console.log("Channels current state is " + states.current);
                break;
            }
          }
        );
      }
    },
    reloadPage() {
      if (
        this.qrcode != "undefined" &&
        this.qrcode != null &&
        this.qrcode.length > 3
      ) {
        if (this.currentQRCode != this.qrcode)
          this.$router.push(`/${this.qrcode}`);
        this.findCompany();
      } else {
        let aMessage = this.$t("messages.invalid_code");
        this.alerts = [];
        this.alerts.push({
          title: "Mesas",
          content: aMessage,
          type: "danger",
        });
        this.$refs.company_code.focus();
      }
    },
    async getHasPlatformsPayment() {
      /*let headers = {
        "Access-Control-Allow-Origin": "*",
      };*/
      /* var axios = require("axios");
      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });*/
      this.requestingInfo = true;
      console.log("tenantApiUrlBase", this.$root.tenantApiUrlBase);
      this.$http
        .get(
          this.$root.tenantApiUrlBase +
            `/api-v1/settings/haspaymentplatformqrmovil/` +
            this.hotel.id +
            `/hotels`
        )
        .then((response) => {
          let setting_id = 0;
          setting_id = response.data[0].pivot.value;
          setting_id == 1
            ? this.$store.commit("setHasPaymentPlatform", true)
            : this.$store.commit("setHasPaymentPlatform", false);

          this.requestingInfo = false;
        })
        .catch((e) => {
          console.log("e", e);
          this.requestingInfo = false;
        });
    },
  },
  computed: {
    ...mapGetters(["isValidTable"]),
    ...mapState({
      productBeingEdited: (state) => state.productBeingEdited,
    }),

    isInvalidRestaurant() {
      return Object.keys(this.hotel).length == 0;
    },
    currentQRCode() {
      return typeof this.$route.params.id != "undefined"
        ? this.$route.params.id
        : null;
    },
    isInvalidCompany() {
      return Object.keys(this.company).length == 0 && this.isInvalidRestaurant;
    },
  },
};
</script>

<style scoped>
.fluid-container.footer {
  background: #1b3d5b;
}
.fluid-container.footer > *:last-child {
  margin-bottom: 0px;
  color: #fff;
}
</style>