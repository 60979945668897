export default {
  en: {
    title:{
      'call_waiter': 'Call the waiter',
      'see_menu': 'See the menu',
      'request_account': 'Request the account',
      'place_order': 'Place the order',
      'see_order': 'See the order',
      'table': 'Table | Tables',
      'new_table':'New table',
      'waiter': 'Wait | Waiters',
      'client': 'Client:',
      'invited':'Invited',
      'menu':'Menu',
      'number_of_accounts':'Number of the accounts:',
      'product':'Product | Products',
      'price':'Price | Prices',
      'amount':'Amount',
      'confirm_products': 'Confirm order',
      'pay_account': 'Pay the account',
      'pay': 'Pay',
      'make_payment': 'Make the payment',
      'add_products' : 'Add',
      'removed_product':'Remove',
      'updated_product':'Update',
      'special_requests':'Special requests',
      'back': 'Back',
      'account':'Account | Accounts',
      'see_account':'See account',
      'total':'Total',
      'quantity':'Quantity',
      'confirmed_product':'Product confirmed',
      'return_menu':'Return to menu',
      'return_account':'Return to the account',
      'comments': 'Comments',
      'next_template': 'Next template',
      'extras' : 'EXTRA | EXTRAS',
      'of':'DE',
      'without_extras':'Without additional features',
      'edit_product' : 'EDIT PRODUCT',
      'see_product' : 'SEE PRODUCT',
      'choose_option':'You have the option to select ',
      'type_of': 'type(s) of',
      'choose_your': 'Select your',
      'select_product_package': 'Select the products that will include the package',
      'select_product':'Select a product',
      'package' : 'PACKAGE | PACKAGES',
      'lang':'Lang',
    },
     
    info: {
      'wait': 'Wait a moment...',
      'connecting': 'Connecting',
      'loading': 'Loading data...',
      'welcome': 'Welcome',
      'to':'to',
      'welcome_customer': 'Welcome dear customer! Please enter a name for identify it:',
      'thank_preference' : '¡Thank you very much for your preference!',
      'come_back':'¡Come back soon!',
      'redirecting_in': 'Redirecting in',
      'second':'Second(s)...',
      'opss' : 'Ooops!',
      'menu_not_available': 'The menu is not available at this time.',
      'table_in_service': 'This table is already in service, please contact the manager in charge.',
      'requested_restaurant':'The requested restaurant was not found.',
      'table_not_found':'The requested table was not found or has already been closed.',
      'account_closed':'(Closed)',
      'account_locked':'(Locked)',
      'account_cancelled':'(Cancelled)',
      'incomplete_configuration':'Incomplete configuration, you need to confirm',
      'loading_menu':'Wait, loading menu',
      'confirm_selection':'Confirm selection',
      'cancel':'Cancel',
      'back_propuct':'Back to product',
      'num_max_product':'The num max of the product is:',
      'select_options':'Select one of these two options',
      'consumption_note':'Consumption note',
      'suggested_tips':'Suggested tips',
      'info_the_pay':'Information of pay',
      'paypal':'Paypal',
    },
    messages: {
      'call_made_staff':'Service call made to staff',
      'action_type_menu':'In a moment we offer you the letter',
      'action_type_bill':'Table ticket is on the way',
      'action_type_order':'At one point a waiter comes over to take your order',
      'not_connected_table': 'Not connected to the table, check with the manager on duty',
      'not_reservation_loaded': 'The reservation data was not loaded correctly',
      'invalid_code':'Invalid code. Check again.',
      'error_code': 'Error validating the code:',
      'not_product_removed':'Product could not be removed:',
      'not_change_quantity': 'Could not change quantity:',
      'error_confirming_products':'Error confirming products:',
      'saved_product':'Product added correctly',
      'updated_product':'El producto fue actualizado correctamente',
      'saved_package':'Package added correctly',
      'not_saved_product':'Product not added:',
      'not_saved_package':'Package not added:',
      'error_reload_accounts': 'Error when reloading the accounts',
      'cancel_pay':'Payment has been cancelled.',
      'wait_save_pay':'Wait, saving payment details...',
      'wait_save_pay_account':'Wait, we are saving the payment in the account...',
      'thanks_close_account':'Thank you very much, your payment was successful, we are closing the account, you will receive a payment email shortly...'
      
    }

  }

}
